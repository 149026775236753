var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-speciality","visible":_vm.isAdd,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":"","no-close-on-backdrop":""},on:{"change":function (val) { return _vm.$emit('update:is-add', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.isEdit ? "Editar" : "Agregar nuevo")+" Usuario")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){[hide(), _vm.resetForm()]}}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit(_vm.items)}}},[_c('validation-provider',{attrs:{"name":"userphoto","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Foto de Perfil","label-for":"aplication-img"}},[_c('div',{staticClass:"d-flex flex-row justify-content-center w-100 h-100"},[_c('b-media',{staticClass:"mb-2 flex-column flex-xl-row w-100",attrs:{"id":"aplication-img"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('b-avatar',{ref:"previewEl",staticClass:"avatar_img relative",attrs:{"variant":"light-info","size":"120px","rounded":""}},[_c('img',{staticClass:"w-100 h-100",attrs:{"src":_vm.tempUrl != ''
                            ? _vm.apiurl + _vm.tempUrl
                            : _vm.urlFile
                            ? _vm.urlFile
                            : _vm.sideImg,"alt":""}})])],1)]},proxy:true}],null,true)}),_c('div',{staticClass:"box_buttons d-flex flex-column mt-1 w-100"},[_c('div',{staticClass:"text-center w-100"},[_c('b-button',{staticClass:"w-100",attrs:{"variant":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.deleteImage(true)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon","size":"18"}}),_vm._v("Eliminar")],1)],1),_c('div',{staticClass:"w-100 mt-1",on:{"click":function($event){return _vm.$refs.fileImg.$el.click()}}},[_c('b-form-file',{ref:"fileImg",staticClass:"form_file",attrs:{"hidden":true,"plain":"","id":"inputTagFile","accept":".png,.jpg"},on:{"change":_vm.uploadImage}}),_c('label',{staticClass:"block cursor-pointer w-100",attrs:{"for":"fileImg"}},[_c('div',{staticClass:"text-center"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"button_file w-100",attrs:{"variant":"success","size":"md"}},[_c('feather-icon',{attrs:{"icon":"UploadIcon","size":"18"}}),_vm._v(" Subir ")],1)],1)])],1)])],1)])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"name","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombre","label-for":"name"}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"name","trim":"","placeholder":"Nombre","autocomplete":"off"},model:{value:(_vm.items.name),callback:function ($$v) {_vm.$set(_vm.items, "name", $$v)},expression:"items.name"}}),_c('small',{staticClass:"text-danger alert",style:({
                height: (errors.length > 0 ? 20 : 0) + 'px',
              })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"dni","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"DNI","label-for":"dni"}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"dni","trim":"","placeholder":"DNI","autocomplete":"off"},model:{value:(_vm.items.dni),callback:function ($$v) {_vm.$set(_vm.items, "dni", $$v)},expression:"items.dni"}}),_c('small',{staticClass:"text-danger alert",style:({
                height: (errors.length > 0 ? 20 : 0) + 'px',
              })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"requeridoE|correo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"email","trim":"","placeholder":"Email","autocomplete":"new-password","autocorrect":"off","autocapitalize":"off","spellcheck":"false"},model:{value:(_vm.items.email),callback:function ($$v) {_vm.$set(_vm.items, "email", $$v)},expression:"items.email"}}),_c('small',{staticClass:"text-danger alert",style:({
                height: (errors.length > 0 ? 20 : 0) + 'px',
              })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.isEdit)?_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Contraseña")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"password","trim":"","type":_vm.passwordFieldType,"name":"login-password","autocomplete":"new-password","autocorrect":"off","autocapitalize":"off","spellcheck":"false","aria-autocomplete":"none","placeholder":"Contraseña"},model:{value:(_vm.items.password),callback:function ($$v) {_vm.$set(_vm.items, "password", $$v)},expression:"items.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1)],1):_vm._e(),(!_vm.isEdit)?_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Contraseña")])]),_c('validation-provider',{attrs:{"name":"password","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"password","trim":"","type":_vm.passwordFieldType,"name":"login-password","autocomplete":"new-password","autocorrect":"off","autocapitalize":"off","spellcheck":"false","aria-autocomplete":"none","placeholder":"Contraseña"},model:{value:(_vm.items.password),callback:function ($$v) {_vm.$set(_vm.items, "password", $$v)},expression:"items.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger alert",style:({
                height: (errors.length > 0 ? 20 : 0) + 'px',
              })},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('div',[_c('h4',{attrs:{"for":"lesson"}},[_vm._v("Acceso Restricciones")]),_c('validation-provider',{attrs:{"name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Perfil","label-for":"role"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":(_vm.isClient && _vm.user_role == 'admin') ? _vm.role_restriccionsPlannerMulti : _vm.role_restriccionsPlanner,"label":"text","input-id":"role","placeholder":"Tipo de Usuario","autocomplete":"off","reduce":function (role) { return role.value; },"disabled":_vm.items.role_restriccion == 'user-collection' && _vm.isEdit},on:{"input":function($event){return _vm.selectRoleRestriction(_vm.items.role_restriccion)}},model:{value:(_vm.items.role_restriccion),callback:function ($$v) {_vm.$set(_vm.items, "role_restriccion", $$v)},expression:"items.role_restriccion"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"name","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.role_restriccion == 'user-collection'),expression:"items.role_restriccion == 'user-collection'"}],attrs:{"label":"Agrupaciones","label-for":"group"}},[_c('v-select',{staticClass:"select-obra fullInput",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.groups,"label":"description","input-id":"group","reduce":function (groups) { return groups.id; },"placeholder":"Agrupaciones"},on:{"input":_vm.selectGroup},model:{value:(_vm.items.groups_id),callback:function ($$v) {_vm.$set(_vm.items, "groups_id", $$v)},expression:"items.groups_id"}})],1)]}}],null,true)}),_c('validation-provider',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.role_restriccion == 'user-collection'),expression:"items.role_restriccion == 'user-collection'"}],attrs:{"name":"name","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Proyecto","label-for":"name"}},[_c('v-select',{staticClass:"select-obra fullInput",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.projectsMulti,"label":"description","input-id":"projects_multi","reduce":function (projects) { return projects.id; },"placeholder":"Proyecto","multiple":"","disabled":""},model:{value:(_vm.items.projects_id),callback:function ($$v) {_vm.$set(_vm.items, "projects_id", $$v)},expression:"items.projects_id"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"name","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.role_restriccion),expression:"items.role_restriccion"}],attrs:{"label":_vm.items.role_restriccion == 'user-collection' ? 'Area Codueño' : 'Area Dueño/Codueño',"label-for":"area_owner"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.items.role_restriccion == 'user-collection' ? _vm.areas_ownerMulti : _vm.areas_owner,"label":"code","input-id":"area_owner","reduce":function (areas_owner) { return areas_owner.id; },"placeholder":_vm.items.role_restriccion == 'user-collection' ? 'Area Codueño' : 'Area Dueño/Codueño'},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.area_id),callback:function ($$v) {_vm.$set(_vm.items, "area_id", $$v)},expression:"items.area_id"}}),_c('small',{staticClass:"text-danger alert",style:({
                  height: (errors.length > 0 ? 20 : 0) + 'px',
                })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSecondSelect),expression:"showSecondSelect"}],attrs:{"name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Telefono","label-for":"phone"}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"phone","trim":"","placeholder":"Telefono"},model:{value:(_vm.items.phone),callback:function ($$v) {_vm.$set(_vm.items, "phone", $$v)},expression:"items.phone"}}),_c('small',{staticClass:"text-danger alert",style:({
                  height: (errors.length > 0 ? 20 : 0) + 'px',
                })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2 justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"disabled":invalid,"variant":"primary","type":"submit"}},[(_vm.isDisabled)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.isDisabled)?_c('span',{staticClass:"px-1"},[_vm._v("guardando...")]):_c('span',[_vm._v(_vm._s(_vm.isEdit ? "Actualizar" : "Agregar"))])],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){[hide(), _vm.resetForm()]}}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }