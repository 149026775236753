<template>
  <!-- eslint-disable -->
  <div>
    <add-edit :is-add.sync="isAdd" :is-client.sync="isClient" ref="dueñoAdd"> </add-edit>
    <b-card no-body>
      <b-card-header class="px-2 py-2 pb-0">
        <b-row class="w-100">
          <b-col lg="2" class="mb-lg-0 mb-1">
            <b-form-group label="Correo" label-for="correo">
              <b-form-input
                @input="filter"
                v-model="correo"
                id="correo"
                placeholder="Correo"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col lg="3" class="mb-lg-0 mb-1">
            <b-form-group label="Nombre" label-for="name">
              <b-form-input
                @input="filter"
                v-model="name"
                id="name_search"
                placeholder="Nombre"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col lg="2" class="mb-lg-0 mb-1">
            <b-form-group label="DNI" label-for="dni">
              <b-form-input
                @input="filter"
                v-model="dni"
                id="dni_search"
                placeholder="DNI"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col md="3" class="pl-0">
            <b-form-group
              label="Proyectos"
              label-for="project_id"
              class="mr-2 w-100"
            >
              <v-select
                @input="filter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="projects"
                label="code"
                input-id="project"
                :reduce="(projects) => projects.id"
                placeholder="Proyecto"
                v-model="project_id"
                class="select-obra"
              >
                <template v-slot:selected-option="option">
                  {{ option.code }} - {{ option.description || "" }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.code }} - {{ option.description || "" }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>         
          <b-col
            lg="2"
            class="mb-lg-0 mb-2 d-flex justify-content-center justify-content-lg-between align-items-center"
          >
            <b-button variant="primary" class="" @click="addUsers">
              <span class="text-nowrap"
                ><feather-icon icon="PlusCircleIcon" size="15" /> Agregar</span
              >
            </b-button>
          </b-col>
          <b-col lg="12">
            <b-form-group label="Tipo" label-for="tipo">
            <b-form-radio-group
              id="radio-group-2"
              v-model="type"
              name="radio-sub-component"
            >
              <b-form-radio
                v-model="type"
                name="type"
                value="001"
                class="mr-4"
                @change="handleRadioFollow()"
                v-show="isRestriction"
              >
                Proyecto
              </b-form-radio>
              <b-form-radio
                v-model="type"
                name="type"
                value="002"
                class="mr-4"
                @change="handleRadioFollow()"
                v-show="isRestriction"
              >
                Subcontratista / Servicios
              </b-form-radio>
              <b-form-radio
                v-model="type"
                name="type"
                value="003"
                class="mr-4"
                @change="handleRadioFollow()"
                v-show="isRestriction"
              >
                Cliente
              </b-form-radio>
              <b-form-radio
                v-model="type"
                name="type"
                value="004"
                class="mr-4"
                @change="handleRadioFollow()"
                v-show="isRestriction"
              >
                Oficina Principal
              </b-form-radio>
              <!-- <b-form-radio
                v-model="type"
                name="type"
                value="005"
                class="mr-4"
                @change="handleRadioFollow()"
                v-show="isLesson"
              >
                Lecciones Aprendidas
              </b-form-radio> -->
            </b-form-radio-group>
          </b-form-group>
          </b-col>
        </b-row>
        
      </b-card-header>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable*/
import Vue from "vue";
import { BootstrapVue, VBToggle } from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import addEdit from "./add-edit.vue";
import ProjectsService from "@/services/ProjectsService";
Vue.use(BootstrapVue);
export default {
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      type: '001',
      correo: "",
      name: "",
      dni: "",
      isAdd: false,
      isClient: false,
      isUpdate: false,
      see_more: false,
      codigo: "",
      company_id: null,
      roleFilter: null,
      records: [],
      projects: [],
      isRestriction: JSON.parse(localStorage.getItem("userData")).role_user[0]
        .is_restriction,
        isLesson: JSON.parse(localStorage.getItem("userData")).role_user[0]
        .is_lesson,
        role_restriction: JSON.parse(localStorage.getItem("userData")).role_user[0]
        .role_restriction,
      project_id: null,
      showEntrie: 10,
      arrayFilters: [],
    };
  },
  components: {
    vSelect,
    addEdit,
  },
  mounted() {
    this.getProjects();
    if (this.isRestriction) {
     
      
      if(localStorage.getItem("project_id")!=null && localStorage.getItem("project_id")!='null'){     
       
        this.project_id = JSON.parse(localStorage.getItem("project_id"))
      }
      this.filter();
    }
    if(this.isLesson){
      this.filter();
    }
  },
  methods: {
    async handleRadioFollow() {
      if(this.type=="003"){
        this.isClient = true;
      }else{
        this.isClient = false;
      }
      await this.filter();
    },
    async getProjects() {
      const url = `?limit=100&`;
      this.showLoading = true;
      const respProyectos = await ProjectsService.getProyectosAll(
        url,
        this.$store
      );
    
      if (respProyectos.status) {
        this.projects = respProyectos.data.rows;
       
      }
    },
    clickSeeMore() {
      this.see_more = !this.see_more;
    },
    addUsers() {
     
      if(this.type =='004'){
        this.$swal({
                title: "Error!",
                text:
                  "Vista no permitida para crear Usuarios Oficina Principal",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
      }else if(!this.project_id){
        this.$swal({
                title: "Error!",
                text:
                  " Tiene que seleccionar un proyecto",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
      }else {
        this.isAdd = true;
        this.$refs.dueñoAdd.setData({});
        this.$refs.dueñoAdd.initValues({projectId:this.project_id , type:this.type});
      }

    },
    async filter() {
      var arrayFilter = [];
      console.log(this.type)
      console.log("ES RESTRICTION",this.isRestriction)
      console.log("ES LESSON",this.isLesson)

      if(this.isRestriction && this.type!='005'){
        arrayFilter.push({
            keyContains: "role_user.some",
            key: "role_restriction",
            value: { in: ['planner','user', 'user-collection','user-office'] },
          });   
      }
      if(this.isLesson  && this.type=='005'){
        arrayFilter.push({
            keyContains: "role_user.some",
            key: "role_lesson",
            value: { in: ['admin-lesson', 'reviewer-lesson','user-lesson'] },
          });   
      }
      if (this.correo != "" && this.correo != null) {
        arrayFilter.push({
          keyContains: "email",
          key: "contains",
          value: this.correo,
        });
      }
      if (this.name != "" && this.name != null) {
        arrayFilter.push({
          keyContains: "name",
          key: "contains",
          value: this.name,
        });
      }
      if (this.dni != "" && this.dni != null) {
        arrayFilter.push({
          keyContains: "dni",
          key: "contains",
          value: this.dni,
        });
      }
      if (this.project_id) {
       
        arrayFilter.push({
            keyContains: "project_user.some",
            key: "project_id",
            value: { in: this.project_id },
          });      
        if(this.type){         
          if(this.type=='001'){
              arrayFilter.push({
                  keyContains: "owner.some",
                  key: "type",
                  value: "001",
                });
          }else if(this.type=='005') {
            arrayFilter.push({
              keyContains: "role_user.some",
              key: "is_lesson",
              value: true,
            });
          }else {
            arrayFilter.push({
              keyContains: "co_owner.some",
              key: "area",
              value: { type: this.type },
            });
          }
        }
      }else {

        if(this.type=='005') {
              arrayFilter.push({
                keyContains: "role_user.some",
                key: "is_lesson",
                value: true,
              });
          }else  if(this.type=='001'){
              arrayFilter.push({
                  keyContains: "owner.some",
                  key: "type",
                  value: "001",
                });
          }else {
            arrayFilter.push({
              keyContains: "co_owner.some",
              key: "area",
              value: { type: this.type },
            });
          }
      }
      if(this.type =='004'){
       this.$parent.$parent.validateOptions = false
      }else {
        this.$parent.$parent.validateOptions = true
      }
      
      this.$parent.$parent.arrayFilters = arrayFilter;
      this.$parent.$parent.getData();
    },
    clean() {
      this.name = "";
      this.dni = "";
      this.correo = "";
      var arrayFilter = [];
      this.$parent.$parent.arrayFilters = arrayFilter;
      this.$parent.$parent.getData();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
// .body_card {
//   display: block;
//   transition: 0.3s;
// }
</style>
